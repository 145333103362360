
import {Routes, Route} from "react-router-dom";
import './App.css';
import Hotspots from './components/hotspots.jsx';
import Homepage from "./components/home.jsx";


function App() {

  return(
  <div className='App'>
    <Routes>
      <Route path='/' element={<Homepage/>} />
      <Route path=':hotspotId' element={<Hotspots/>}>
      </Route>
    </Routes>
  </div>
  )
  }


  
export default App;
